import React from 'react'

import '../styles/global.css'

const Layout = ({ children }) => (
  <>
    {children}
  </>
)

export default Layout
